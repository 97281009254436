@import './settings';

.primary-background {
  background-color: $primary-color;
}

.white-background {
  background-color: $white;
}

.line-height-1 {
  line-height: 1;
}
