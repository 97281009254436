// scss-lint:disable ImportantRule PseudoElement PropertySpelling QualifyingElement
@import '../settings';

textarea {
  resize: vertical;
}

label {
  &.required {
    &::after {
      color: get-color(alert);
      content: '\2022';
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1rem;
      margin-left: .25rem;
    }
  }
}

.form-wrapper {
  background-color: $light-gray;
  margin: 0 - map-get($grid-padding-gutters, small);

  @include breakpoint(medium) {
    border-radius: $global-radius;
  }

  .group-name {
    background-color: lighten($primary-color, 10%);
    border-radius: 5px;
    color: $white;
    display: inline;
    font-size: 1.25rem;
    padding: 1rem;
  }

  .note {
    font-size: .8rem;
    font-style: italic;
  }

  .form-header {
    border-bottom: 5px solid $medium-gray;
    font-size: 1.5rem;
    margin-bottom: 0;
    padding: 2rem 1rem;
    text-align: center;
    text-transform: uppercase;

    @include breakpoint(medium) {
      padding: 2rem;
    }
  }

  .form-content {
    padding: 2rem 1rem;

    @include breakpoint(medium) {
      padding: 2rem;
    }
  }

  input[type='radio'] {
    accent-color: $primary-color;
    transform: scale(2);
  }

  input[type='checkbox'] {
    accent-color: $primary-color;
    transform: scale(1.5);
  }

  .form-field {
    color: $white;
    font-size: 1.25rem;
    font-weight: bold;
    word-wrap: break-word;
  }

  .form-field-value-rounded-borders {
    background-color: $light-gray;
    border-bottom: 1px solid;
    border-radius: $global-radius;
    padding: 1rem 1rem 0;
  }

  .form-field-value {
    background-color: $light-gray;
    border-bottom: 1px solid;
    padding: 1rem 1rem 0;
  }

  .round-top-borders {
    border-top-left-radius: $global-radius;
    border-top-right-radius: $global-radius;
  }

  .round-bottom-borders {
    border-bottom-left-radius: $global-radius;
    border-bottom-right-radius: $global-radius;
  }

  ::placeholder {
    color: $dark-gray;
    opacity: 1; // Firefox
  }

  :-ms-input-placeholder { // Internet Explorer 10-11
    color: $dark-gray;
  }

  ::-ms-input-placeholder { // Microsoft Edge
    color: $dark-gray;
  }
}

form {
  background-color: lighten($primary-color, 10%),;
  border-bottom: 1px solid;
  border-radius: $global-radius;
  color: $white;
  margin: 1rem 0;
  padding: 1rem;
}

.error-explanation {
  border: 1px solid get-color(alert);
  border-radius: $global-radius;
  box-shadow: inset 0 0 5px get-color(alert);
  margin-bottom: $global-margin;

  h2 {
    background: get-color(alert);
    color: $white;
    font-size: $small-font-size;
    font-weight: bold;
    margin: 0;
    padding: .5 * $global-padding;
  }

  ul {
    margin: .5 * $global-margin 2 * $global-margin;
  }
}

// sass-lint:enable id-name-format, no-ids

.field-with-errors {
  label {
    color: darken(get-color(alert), 10%);
    font-weight: bold;

    &.message {
      font-size: $small-font-size;
      margin: -$global-margin 0 $global-margin;
    }
  }

  [type='color'],
  [type='date'],
  [type='datetime'],
  [type='datetime-local'],
  [type='email'],
  [type='month'],
  [type='number'],
  [type='password'],
  [type='search'],
  [type='tel'],
  [type='text'],
  [type='time'],
  [type='url'],
  [type='week'],
  select,
  textarea {
    border-bottom: 1px solid get-color(alert);
    box-shadow: 2px 1px 1px get-color(alert);
  }
}

input {
  &[type='radio'] {
    // sass-lint:disable no-vendor-prefixes
    -moz-appearance: button;
    -webkit-appearance: button;
    border: $input-border;
    border-radius: 50%;
    height: 1 * $form-label-font-size;
    margin-bottom: 0;
    margin-right: $global-margin;
    position: relative;
    top: 2px;
    width: 1 * $form-label-font-size;

    &:checked {
      border: 8px solid get-color(success);
    }

    &:focus {
      outline: none;
    }

    // scss-lint:disable NestingDepth
    + label {
      &.button {
        margin-bottom: $global-margin;
      }
    }
  }

  &[type='checkbox'] {
    -moz-appearance: button;
    -webkit-appearance: button;
    border: $input-border;
    border-radius: 50%;
    height: 1 * $form-label-font-size;
    margin-bottom: 0;
    margin-right: $global-margin;
    position: relative;
    top: 2px;
    width: 1 * $form-label-font-size;

    &:checked {
      border: 8px solid get-color(success);
    }

    &:focus {
      outline: none;
    }

    // scss-lint:disable NestingDepth
    + label {
      &.button {
        margin-bottom: $global-margin;
      }
    }
  }
}

.input-group-field {
  &.date {
    max-width: 150px;
  }
}

.input-group-button {
  &:nth-last-child(2) {
    button {
      border-radius: 0;
    }
  }
}

.text-other {
  margin-bottom: 0;
  margin-left: $global-margin;
}
