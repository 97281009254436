@import '../settings';

.slidecontainer {
  position: relative;
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  background: $white;
  border-radius: 5px;
  height: 15px;
  opacity: 1;
  outline: none;
  -webkit-transition: .2s;  // scss-lint:disable VendorPrefix
  transition: opacity .2s;
  width: 100%;
  z-index: 1;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  background: darken($primary-color, 10%);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.slider::-moz-range-thumb {
  background: darken($primary-color, 10%);
  border-radius: 50%;
  cursor: pointer;
  height: 25px;
  width: 25px;
}

.ticks {
  display: flex;
  justify-content: space-between;
  padding: 0 9px;
  position: absolute;
  top: 33px;
  width: 100%;

  .tick {
    border-top: 20px solid $white;
    height: 20px;
    width: 6px;
  }
}
