// scss-lint:disable ImportantRule PropertySortOrder PropertySpelling
@import '../settings';

.report {
  .callout {
    li {
      margin-bottom: 1rem;

      // scss-lint:disable NestingDepth
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media print {
    section {page-break-after: always;}
    .card-divider {page-break-after: avoid;}

    // scss-lint:disable QualifyingElement
    a[href]::after {
      content: none !important;
    }
  }
}

* {
  -webkit-print-color-adjust: exact !important;   // Chrome, Safari 6 – 15.3, Edge
  color-adjust: exact !important;                 // Firefox 48 – 96
  print-color-adjust: exact !important;           // Firefox 97+, Safari 15.4+
}
